import React from 'react';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import ReactGA from 'react-ga';
import './App.css';


import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/authActions';


class App extends React.Component {
   constructor(){
     super();
     
     
    if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
      ReactGA.initialize('UA-125155888-13');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
   }
  componentDidMount(){
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Provider store={store} >
        <div className="App">
          <Header />
          
          <Footer />
        </div>
      </Provider>
    );
  }
}

export default App;
