import React from 'react';
import { Carousel, Card, CardDeck } from 'react-bootstrap';
import './ControlledCarousel.css';


class ControlledCarousel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
      brands: props.brands
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  render() {
    const { index, direction } = this.state;

    return (
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
        fade={true}
      >
        <Carousel.Item>
            <CardDeck>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/blackstar.jpg')} />
                <Card.Body>
                  <Card.Title>Blackstar</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/mesa.jpg')} />
                <Card.Body>
                  <Card.Title>Mesa</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/gretsch.jpg')} />
                <Card.Body>
                  <Card.Title>Gretsch</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/orange.jpg')} />
                <Card.Body>
                  <Card.Title>Orange</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
        </Carousel.Item>
        <Carousel.Item>
            <CardDeck>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/marshall.jpg')} />
                <Card.Body>
                  <Card.Title>Marshall</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/fender.jpg')} />
                <Card.Body>
                  <Card.Title>Fender</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/peavy.jpg')} />
                <Card.Body>
                  <Card.Title>Peavy</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/AmpBrands/kustom.jpg')} />
                <Card.Body>
                  <Card.Title>Kustom</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default ControlledCarousel;