import React from 'react';
import { Row, Col, Container, Carousel } from 'react-bootstrap';
import StudioCard from '../../items/Studio/StudioCard';
import ReactGA from 'react-ga';

class Studio extends React.Component {
    constructor(){
        super();
        if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
          ReactGA.pageview(window.location.pathname + window.location.search);
        }

        this.state = {
            studios: [
                {
                    id: 3,
                    name: 'Drum Studio',
                    desc: '',
                    fileName: 'studio_clean.jpg'
                },
                {
                    id: 2,
                    name: 'Studio DC',
                    desc: '',
                    fileName: 'studiodc.jpg'
                }
            ]
        };
    }
    // Jump to top of window
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <div className="Studio">
                <div className="picBgStudio">
                </div>
                <Container className="about-section">
                    <Row>
                        <Col>
                            <h2>Schedule Studio Time</h2>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h5>
                          To schedule studio time give us a call at <a href="tel:+15137371900" className="special-text tel">(513) 737-1900</a>
                        </h5>
                        <p>
                          We have two studios available for sessions. Our drum studio features a control room with a mixing board from the early 1980's
                          with a reel-to-reel tape machine from 1979. Using this vintage technology, you can give your music that
                          authentic, vintage feel that you just can't achieve using a mouse.
                        </p>
                        <br/>
                        <p>
                          After your session, be sure to add to our room of mixing board labels.
                        </p>
                      </Col>
                        {/*
                            this.state.studios.map((studio)=>{
                              return <Col lg={4}><StudioCard studio={studio} key={studio.id} /></Col>
                            })
                          */}
                    </Row>
                    <Row>
                      <Carousel>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={require('../../../img/sessionslabels.jpg')}
                            alt="First slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={require('../../../img/sessionslabels2.jpg')}
                            alt="Third slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={require('../../../img/sessionslabels3.jpg')}
                            alt="Third slide"
                          />
                        </Carousel.Item>
                      </Carousel>
                    </Row>
                    <Row>
                      <Col>
                      </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Studio;