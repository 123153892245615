import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import './Footer.css';

class Footer extends React.Component {
    render(){
        return (
            <div className="Footer">
                <Container>
                    <Row>
                        <Col xs={12} md={3} lg={3}>
                            <h3><i className="fal fa-phone-volume"></i> Contact Us</h3>
                            <div className='contact-icons'>
                                <i className="fal fa-phone"/>  <a href="tel:+15137371900" className="tel-footer">(513) 737-1900</a><br/>
                                <i className="fal fa-envelope"/> miles@3rdstreetmusic.com<br/>
                                <i className="fal fa-clock"/> Mon-Thur: 12pm-8pm <br/>
                                <i className="fal fa-clock"/> Fri: 12am-6pm <br/>
                                <i className="fal fa-clock"/> Sat: 10pm-6pm <br/>
                            </div>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                            <h3><i className="fal fa-guitar"></i> About Us</h3>
                        <p>
                          Founded in 2012, owned by father and son Pete and Miles Davidson - 3rd Street Music is the home for all things groovy in Hamilton, Ohio.
                          Located in the German Village District, 3rd Street Music is a place for musicians of all ages and backgrounds. If you're looking to buy your first guitar, practice chords during lessons, or record with professional equipment then 3rd Street Music is where you want to be.
                        </p>
                        
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                            <h3><i className="fal fa-users"></i> Stay Connected </h3>
                            <h5><a rel="noopener noreferrer" href="https://www.instagram.com/3rdstreetmusic/" target="_blank"><i className="fab fa-instagram"></i> Follow us on Instagram!</a></h5>
                            <h5><a rel="noopener noreferrer" href="https://www.facebook.com/3rdStreetMusic/" target="_blank"><i className="fab fa-facebook-square"></i> Like us on Facebook!</a></h5>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                            <h3><i className="fal fa-map-marker-smile"></i> Come visit us </h3>
                            <div className="map-box">
                                <iframe title="gMapLocation" className="g-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3082.978592137163!2d-84.56091518477487!3d39.401990379495935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884046f8b455ce07%3A0x4db73902b6456a50!2s3rd+Street+Music!5e0!3m2!1sen!2sus!4v1559381497674!5m2!1sen!2sus" frameBorder="0" allowFullScreen></iframe>
                            </div>
                        
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="a3-footer">
                        <Col lg={12} md={12} sm={12}>
                          <a rel="noopener noreferrer" style={{display: 'inline-block'}} target="_blank" href="https://a3illc.com"><img alt='A3 Innovation logo' src={require('../../../img/a3i_footer_logo.png')} style={{width: '100px', display: 'inline-block'}}/><p style={{display:'inline-block'}} className="footer-poweredBy">Powered by A3 Innovation</p></a>
                          <p className="footer-poweredBy">&nbsp; &copy; &nbsp;2019 All Rights Reserved</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Footer;