import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, NavLink, Alert
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { addEvent } from '../../../actions/scheduleActions';
import { register } from '../../../actions/authActions';
import { clearErrors } from '../../../actions/errorActions';
import axios from 'axios';

class NewUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      newUserRole: null,
      phone: '',
      roles: [
        {
          id: 1,
          name: 'Organizer'
        },
        {
          id: 3,
          name: 'Teacher'
        },
        {
          id: 2,
          name: 'User/Student'
        }
      ]
    }
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  async changeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
  
    await this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: value
      }
    });
  }

  validatePhone(input) {
    // Check if input length is less than or greater than state number
    var number = input.target.value;

    // If number.length == 10 (coming from autofill)
    if(number.length === 10 && !number.includes('-')){
        number = this.insert(number, 3, '-');
        number = this.insert(number, 7, '-');
    }

    // If number.length is less than the state, user is deleting and state should be updated
    if (number.length < this.state.phone.length){
      this.setState({phone: number});
      this.changeHandler(input);
    }

    // If greater, add to state as long as number.length < 13
    else if(number.length < 13 && number.length > this.state.phone.length){
      if(number.length === 3 || number.length === 7) {
          number = number + '-';
      }
      this.setState({phone: number});
      this.changeHandler(input)
    }
}

  onSubmit = async(e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    var { first_name, last_name, email, phone, password } = this.state;
    var role = this.state.newUserRole;

    if(!first_name || !last_name || !email || !phone || !password || !role) {
      alert('Please fill out all fields');
    } else {
      var newUser = {
        first_name,
        last_name,
        email,
        phone,
        password,
        role_id: role
      }
      await axios.post(`${ process.env.REACT_APP_API_URL }/user/`, newUser, config)
        .then(result => {
          if(result.status == 200) {
            alert('User created successfully.')
          }
          this.setState({phone: '', email: '', first_name: '', last_name: '', password: '', newUserRole: null})
        }).catch(err => {
          alert('There was an issue trying to create the new user. Please try again later.')
        })
    }
  };

  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const userRole = (this.props.user && this.props.user.role) ? this.props.user.role.name : 'User';
    return (
      <div style={{display: 'inline-block', paddingTop: '10px'}}>
        {
          (isAuthenticated && (userRole == 'Organizer' || userRole == 'Teacher')) 
          ? <Button className="call-to-action-btn" onClick={this.toggle}>
              Create New User
            </Button>
          : null
        }
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Create a new user</ModalHeader>
            <ModalBody>
              {
                this.state.msg ? (
                <Alert color='danger'>{this.state.msg}</Alert>
              ) : null
              }
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <Label for='start'>First Name</Label>
                  <Input
                    type='text'
                    name='first_name'
                    id='first_name'
                    className='mb-3'
                    onChange={this.onChange}
                  />
                  <Label for='last_name'>Last Name</Label>
                  <Input
                    type='text'
                    name='last_name'
                    id='last_name'
                    className='mb-3'
                    onChange={this.onChange}
                  />
                  <Label for='email'>Email</Label>
                  <Input
                    type='text'
                    name='email'
                    id='email'
                    className='mb-3'
                    onChange={this.onChange}
                  />
                  <Label for='phone'>Phone</Label>
                  <Input
                    type='text'
                    name='phone'
                    id='phone'
                    value={this.state.phone}
                    onChange={e => this.validatePhone(e)}
                    className='mb-3'
                  />
                  <Label for='password'>Password</Label>
                  <Input
                    type='password'
                    name='password'
                    id='password'
                    onChange={this.onChange}
                    className='mb-3'
                  />
                  <Label for="role">Select a Role</Label> &nbsp;
                  <select onChange={e => this.setState({newUserRole: e.target.value || null})} name="role" value={this.state.newUserRole || ''}>
                  <option value=''></option>
                    
                    {
                      (this.state.roles && userRole == 'Organizer')
                      ? this.state.roles.map((role)=>{
                          return <option key={role.id} value={role.id}>{role.name}</option>
                        })
                      : null
                      }
                      {
                      (this.state.roles && userRole == 'Teacher')
                      ? <option key={2} value={2}>User/Student</option>
                      : null
                        
                      }
                  </select>
                  <Button type="submit" className="call-to-action-btn" style={{ marginTop: '2rem' }} block>
                    Submit
                  </Button>
                  
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  user: state.auth.user,
})

export default connect(
  mapStateToProps,
  { register, clearErrors, addEvent }
)(NewUserModal)