import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import TeacherCard from '../../items/Teacher/TeacherCard';
import './Lessons.css';
import axios from 'axios';
import ReactGA from 'react-ga';


class Lessons extends React.Component {
    constructor(){
      super();
      if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
        ReactGA.pageview(window.location.pathname + window.location.search);
      }


      this.state = {
        teachers: [
          {
            id: 1,
            name: 'Aric',
            desc: 'Aric is really good at eating and sleeping. Loves the blues and oldies rock',
            fileName: 'guitars.jpg'
          },
          {
            id: 2,
            name: 'Alex',
            desc: 'Alex is really good at coming up with ideas and never sleeping. Loves the classical music',
            fileName: 'studio.jpg'
          },
          {
            id: 3,
            name: 'Ryan',
            desc: 'Ryan is really good at being ryan. Loves the Eminem',
            fileName: 'guitars.jpg'
          }
        ]
      };
    }
    // When component gets loaded
    componentDidMount() {
        // Jump to top of window
        window.scrollTo(0, 0);
        // Get all teachers and set state with returned array
        /* axios.get(`${ process.env.REACT_APP_API_URL }`)
          .then(response => {
              console.log(response);
          }).catch(err => {
              console.log(err);
          }) */
    }


    render(){
        return (
            <div className="Lessons">
                <div className="picBg">
                </div>
                <Container className="about-section">
                    <Row>
                        <Col>
                            <h2>Call today to schedule music lessons!</h2>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          Featuring two lesson studios and a team of experienced musicians, 3rd Street Music offers
                          music lessons on most instruments for all ages and skill levels. <br/>
                          To find out more about our music lessons, give us a call at <a href="tel:+15137371900" className="special-text tel">(513) 737-1900</a>
                        </p>
                        <h5>Online scheduling coming soon!</h5>
                          {/* this.state.teachers.map((teacher) => {
                              return <Col key={teacher.id} xs={12} sm={12} md={6} lg={4}>
                                      <TeacherCard teacher={teacher} key={teacher.id} />
                                    </Col>
                          })*/}
                      </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Lessons;