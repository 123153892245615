import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, NavLink, Alert
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { addEvent } from '../../../actions/scheduleActions';
import { register } from '../../../actions/authActions';
import { clearErrors } from '../../../actions/errorActions';
import axios from 'axios';

class NewEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      start: null,
      events: this.props.events,
      end: '',
      student: null,
      studio: null,
      students: [],
      teacher: null,
      message: null,
      submitted: false,
      studios: [
        {
            id: 3,
            name: 'Drum Studio',
            desc: '',
            fileName: 'studio_clean.jpg'
        },
        {
            id: 2,
            name: 'Studio DC',
            desc: '',
            fileName: 'studiodc.jpg'
        }

      ],
      teachers: [] 
    }
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  }

  componentWillReceiveProps(newProps) {
    if(this.props.events != newProps.events) {
      this.setState({events: newProps.events});
    }
  }

  async componentDidMount() {
    if(this.props.user && this.props.user.role && this.props.user.role.name == 'Organizer'){
      await axios.get(`${ process.env.REACT_APP_API_URL }/user/role/3`)
        .then(result => {
          if(result.status == 200) {
            this.setState({teachers: result.data.users});
          }
        }).catch(err => {
          console.log(err)
        })
    }
    await axios.get(`${ process.env.REACT_APP_API_URL }/user/role/2`)
      .then(result => {
        if(result.status == 200) {
          this.setState({students: result.data.users});
        }
      })
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  changeHandler = start => {
    this.setState({ end: moment(start).add(30, 'm').toDate() });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ end: moment(e.target.value).add(30, 'm').format('MM/DD/YYYY hh:mm A') });
    this.setState({submitted: false})
  };

  onSubmit = async(e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    var { student, start, end, teacher, studio } = this.state;
    var body;
    body = {...body, student, start, end};
    if(teacher && teacher != null) {
      body = {...body, teacher};
    } else if(studio && studio != null) {
      body = {...body, studio};
    }

    await axios.post(`${ process.env.REACT_APP_API_URL }/events/new`, body, config)
      .then(result => {
        if(result.status == 200) {
          alert('Event created and added to calendar!');
          var newEvent = result.data.event;
          if(newEvent.studio_id) {
            this.props.newEvent(this.props.schedulerData, {
              start: moment(newEvent.event.start).format('YYYY-MM-DD HH:mm:ss'),
              end: moment(newEvent.event.end).format('YYYY-MM-DD HH:mm:ss'),
              title: newEvent.user.user.first_name,
              resourceId: 's' + newEvent.studio_id,
              id: newEvent.event.id,
              client: newEvent.user.user
            })
          } else if(newEvent.teacher_id) {
            this.props.newEvent(this.props.schedulerData, {
              start: moment(newEvent.event.start).format('YYYY-MM-DD HH:mm:ss'),
              end: moment(newEvent.event.end).format('YYYY-MM-DD HH:mm:ss'),
              title: newEvent.user.user.first_name,
              resourceId: 't' + newEvent.teacher_id,
              id: newEvent.event.id,
              client: newEvent.user.user
            })
          }
          //this.props.schedulerData.addEvent(result.data.event);
        }
        this.setState({student: null, teacher: null, studio: null, start: null, end: ''});
        document.getElementsByClassName('event-form')[0].reset();
        this.toggle();
      })
    //this.props.addEvent(eventItem, user)
  };

  checkAvailability = async(e) => {
    e.preventDefault();
    if(this.state.studio != null && this.state.studio != '') {
      var studio = this.state.studio;
    }
    if(this.state.teacher != null && this.state.teacher != '') {
      var teacher = this.state.teacher;
    }
    await this.setState({submitted: true})
    if(teacher && studio){
      alert('Please select only a studio or teacher');
      this.setState({submitted: false})
    } else if(teacher){
      if(!this.state.start || this.state.start == null) {
        alert('Please select a starting time');
        this.setState({submitted: false})
      } else {
        for(let event of this.state.events) {
          if(event.teacher && event.teacher.id == teacher) {
            var e = new Date(event.start);
            var newE = new Date(this.state.start);
            if(e.getTime() == newE.getTime()) {
              this.setState({submitted: false});
              alert(`This time slot is already taken.`);
            }
          }
        }
      }
    } else if(studio){
      if(!this.state.start || this.state.start == null) {
        alert('Please select a starting time');
      } else {
        for(let event of this.state.events) {
          if(event.studio && event.studio.id == studio) {
            var e = new Date(event.start);
            var newE = new Date(this.state.start);
            if(e.getTime() == newE.getTime()) {
              this.setState({submitted: false});
              alert(`This time slot is already taken for that studio.`)
            }
          }
        }
      }
    } else if(!teacher && !studio) {
      alert('Please select a teacher or studio');
      this.setState({submitted: false});
    }

    if(this.state.submitted == true) {
      alert('Time is available! Submit the form to schedule the event.');
    }
  }

  updateTeacher = e => {
    this.setState({teacher: e.input.value});
    this.setState({submitted: false});
  }
  updateStudio = e => {
    this.setState({studio: e.input.value});
    this.setState({submitted: false});
  }

  render() {
    const role = (this.props.user && this.props.user.role && this.props.user.role.name) ? this.props.user.role.name : 'Student';
    const isAuthenticated = this.props.isAuthenticated;

    return (
      <div style={{display: 'inline-block', paddingTop: '10px'}}>
        {
          (isAuthenticated && role == 'Organizer') ? <Button className="call-to-action-btn" onClick={this.toggle}>
                              Schedule Events
                            </Button>
                          : null
        }
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Schedule Studio Time</ModalHeader>
            <ModalBody>
              {this.state.msg ? (
                <Alert color='danger'>{this.state.msg}</Alert>
              ) : null}
              <Form className="event-form">
                <FormGroup>
                  <Label for='start'>Start Time</Label>
                  <Input
                    type='datetime-local'
                    name='start'
                    id='start'
                    className='mb-3'
                    onChange={this.onChange}
                  />
                  <Label for='end'>End Time</Label>
                  <Input
                    type='text'
                    name='end'
                    id='end'
                    value={this.state.end}
                    className='mb-3'
                    disabled="disabled"
                  />
                  {
                    (role == 'Organizer')
                    ? (<div><Label for="studio">Select a Studio</Label> 
                    <select onChange={e => this.setState({studio: e.target.value || null})} name="studio" value={this.state.studio || ''}>
                    <option value=''></option>        
                      {(this.state.studios)
                      ? this.state.studios.map((studio)=>{
                          return <option key={studio.id} value={studio.id}>{studio.name}</option>
                        })
                      : null}
                    </select><br/></div>

                    ) : null
                  }
                  
                    
                  <Label for="teacher">Select a Teacher</Label> &nbsp;
                  <select onChange={e => this.setState({teacher: e.target.value || null})} name="teacher" value={this.state.teacher || ''}>
                  <option value=''></option>
                    {
                      (this.state.teachers && role == 'Organizer')
                      ? this.state.teachers.map((teacher)=>{
                          return <option key={teacher.user.id} value={teacher.user.id}>{teacher.user.first_name}</option>
                        })
                      : null
                      }
                  </select><br/>
                  <Label for="student">Select a Student/Customer</Label> &nbsp;
                  <select onChange={e => this.setState({student: e.target.value || null})} name="student" value={this.state.student || ''}>
                  <option value=''></option>
                    {
                      this.state.students
                      ? this.state.students.map((student)=>{
                          return <option key={student.user.id} value={student.user.id}>{student.user.first_name}</option>
                        })
                      : null
                      }
                  </select>
                  {
                    (this.state.submitted == false)
                    ? <Button onClick={e => this.checkAvailability(e)} className="call-to-action-btn" style={{ marginTop: '2rem' }} block>
                        Check Availability
                      </Button>
                    : <Button onClick={e => this.onSubmit(e)} type="submit" className="call-to-action-btn" style={{ marginTop: '2rem' }} block>
                        Submit
                      </Button>
                  }
                  
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  user: state.auth.user,
})

export default connect(
  mapStateToProps,
  { register, clearErrors, addEvent }
)(NewEventModal)