import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import ControlledCarouselGuitar from '../../items/ControlledCarousel/ControlledCarouselGuitar';
import ControlledCarouselAmp from '../../items/ControlledCarousel/ControlledCarouselAmp';
import './Showroom.css';
import ReactGA from 'react-ga';

class Showroom extends React.Component {
  constructor() {
    super();

    this.state = {};
    if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }
  // Jump to top of window
  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render(){
    return (
        <div className="Showroom">
            <div className="picBg3">
            
            </div>
            <Container className="about-section">
              <Row className="showroomHeader">
                  <Col lg={9}>
                      <h2>Come for the gear, stay for the vibes.</h2>
                      <p>
                        Come visit us at 230 N. 3rd St, Hamilton, OH. <br/><br/>
                        Check out our new, used, and vintage guitars, pick up any gear your band may need, grab a new amp,
                        and of course don't forget to stock up on incense on your way out.
                      </p>
                  </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="guitarsHeader">Guitars</h4>
                  <h5>Vintage guitars, new and used, electric or acoustic.</h5>
                </Col>
              </Row>
            </Container>
            <Container fluid={true}>
              <Row>
                <Col className="offset-lg-1">
                  <h5 style={{paddingBottom: '15px'}}>Some of our favorite brands</h5>
                </Col>
              </Row>
            </Container>
            <ControlledCarouselGuitar />
            <Button className="btn btn-primary call-to-action-btn" style={{margin: '20px auto'}}><a rel="noopener noreferrer" target="_blank" href="https://reverb.com/shop/3rd-street-music-1?product_type=electric-guitars">Shop our guitars!</a></Button>

            <div className="amps-section">
            <div className="container-fluid">
              <div className="picBgShop">
                <div className="service-wrapper">
                  <Row className="service-summary">
                        <Col className="service-col col-lg-8 offset-lg-2">

                            <h1 className="service-title">Shop Online</h1>
                            <p>
                              <span className="special-text">Visit our reverb shop to buy gear online!</span> <br/> 
                              Guitars, pedals, and more for sale online. Click the button below to visit our online store
                              <br/> <br/>
                            </p>
                            <a href="https://reverb.com/shop/3rd-street-music-1" rel="noopener noreferrer" target="_blank"><div className="btn btn-primary call-to-action-btn">Shop Online</div></a>
                        </Col>
                    </Row>
                  </div>
              </div>
            </div>
            <Container>
              <Row>
                <Col>
                  <h4 className="ampsPedalsHeader">Amps & Pedals</h4>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <h5>Pedals</h5>
                  <p>
                    Your tones been sounding bland? Try spicing things up with an effect pedal.<br/><br/>
                    Earthquaker Devices, Dan Electro, CatlinBread, and more great brands in stock. <br/>
                  </p>
                  <p>
                    Check out our selection of Earthquaker Devices pedals! Through June, get a free EQD hat with the purchase of a Westwood. Every EQD purchase until July 1st enters you to win the EQD wall clock.
                  </p>
                  <Button className="btn btn-primary call-to-action-btn" style={{margin: '20px auto'}}><a rel="noopener noreferrer" target="_blank" href="https://reverb.com/shop/3rd-street-music-1?product_type=effects-and-pedals">Shop our pedals!</a></Button>
                </Col>
                <Col lg={{span: 5, offset: 1}}>
                  <img className="showroom-item" src={require('../../../img/quakers.jpg')} />
                </Col>
              </Row>
              <Row>
                <Col lg={5} className="ampsHeader">
                  <h5>Amps</h5>
                  <p>
                    Want to make noise? Like... a lot of noise? Well lucky you, we have all the tools you need to do 
                    exactly that. Stop in and check out our unique selection of noise makers!
                  </p>
                  <Button className="btn btn-primary call-to-action-btn" style={{margin: '20px auto'}}><a rel="noopener noreferrer" target="_blank" href="https://reverb.com/shop/3rd-street-music-1?product_type=amps">Shop our amps!</a></Button>
                </Col>
              </Row>
            </Container>
            <Container fluid={true}>
              <Row>
                <Col className="offset-lg-1">
                  <h5 style={{paddingBottom: '15px'}}>Some of our favorite brands</h5>
                </Col>
              </Row>
            </Container>
            <ControlledCarouselAmp />
            </div>
        </div>
    )
  }
}


export default Showroom;