import { GET_EVENTS, DELETE_EVENT, ADD_EVENT, UPDATE_EVENT, EVENTS_LOADING } from '../actions/types';

const initialState = {
  events: [],
  event: null,
  loading: false
}

export default function(state = initialState, action) {
  switch(action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false
      }
    case ADD_EVENT:
      return {
        events: state.events.filter(event => event.id !== action.payload.id),
        event: action.payload
      }
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true
      }
    default:
      return state;
  }
}