import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import LinkButton from '../../items/Buttons/LinkButton';
import './Home.css';
import ReactGA from 'react-ga';


class Home extends React.Component {
  constructor(){
    super();
    this.state = {
      
    }
    if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }
  componentDidMount() {
      // Jump to top of window
      window.scrollTo(0, 0);
    }

    render(){
        return (
            <div className="Home">
                <div className="videoBg">
                    <video playsInline autoPlay muted loop >
                        <source src={require('../../../img/guitar-video.mp4')} type="video/mp4" />
                    </video>
                </div>
                <div className="picBg6"></div>
                <div className="home-about">
                <Container className="about-section">
                    <Row>
                        <Col>
                            <h2>3rd Street Music <i className="fal fa-guitar"/></h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={{span: 7}} className="home-img-col">
                            <img className="home-img" src={require('../../../img/storefront.jpg')} alt=""/>
                        </Col>
                        <Col sm={12} lg={{span: 5}}>
                          <h4>A home for all musicians</h4>
                          <p>
                            Located in the German Village in Hamilton, Ohio - 3rd Street Music is
                            a place for musicians of all ages and backgrounds. If you're looking to buy
                            your first guitar, practice during guitar lessons (or other instruments), or record with professional equipment
                            then 3rd Street Music is where you want to be. 
                            <br/> <br/> 
                            Featuring professional music lessons, new and used instruments for sale, and two recording 
                            studios combining the best of modern and vintage technology, we want to provide a home for 
                            musicians of all ages and instruments at any level in their career.
                            <br/> <br/>
                            We take great pride in having the coolest vibe in the tri-state. 
                            Don't believe us? Come feel it yourself.
                            <br/> <br/>
                          </p>
                            <LinkButton route={'/contact'} state={this.state} btnText={'Come feel the vibe!'} />
                        </Col>
                    </Row>
                </Container> 
                </div>
                <div className="container-fluid">
                  <div className="picBg4">
                    <div className="service-wrapper">
                      <Row className="service-summary">
                          <Col className="service-col col-lg-6 offset-lg-3">
                              <h1 className="service-title">Music Lessons</h1>
                              <p>
                                Our professional instructors offer lessons for any instrument and to musicians of any age. Helping you every step of the way, our teachers will guide you through perfecting your talent and mastering your unique style. <br/> <br/>
                              </p>
                              {/* <LinkButton route={'/lessons'} state={this.state} btnText={'Schedule Lessons'} /> */}
                              <span className="special-text">Give us a call at <a href="tel:+15137371900" className="special-text tel">513-737-1900</a> to schedule lessons! Online scheduling portal coming soon!</span>
                          </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="picBg2">
                    <div className="service-wrapper">
                      <Row className="service-summary">
                            <Col className="service-col col-lg-6 offset-lg-3">
                                <h1 className="service-title">Shop</h1>
                                <p>
                                  <span className="special-text">Shop our unique guitar inventory.</span> <br/> 
                                  Dan Electro, D'Angelico, Gibson, Fender, and many more classic styles in stock.
                                  <br/> <br/>
                                  Guitars, amps, pedals, and all the gear your heart desires.
                                  <br/>
                                </p>
                                <LinkButton route={'/showroom'} state={this.state} btnText={'Checkout our Showroom'} />
                            </Col>
                        </Row>
                      </div>
                  </div>
                  <div className="picBg5">
                    <div className="service-wrapper">
                      <Row className="service-summary">
                        <Col className="service-col col-lg-8 col-md-12 col-sm-12 offset-lg-2">
                            <h1 className="service-title">Studio Sessions</h1>
                            <p>
                              The acoustics from an older home combined with a mix of digital and analog production tools,
                              and a super comfortable recording studio creates the perfect environment for you and your
                              band to let your creativity flow. 
                              <br/><br/>
                              We use a mixing board from the early 1980's, with a reel-to-reel mixing tape from 1979 to
                              give your recording session an authentic vintage sound. <br/>
                              Looking for something more modern? No problem. We also offer the latest version of ProTools
                              to compose, record, and mix your audio with the best tools in the industry.
                              <br/><br/>
                            </p>
                            {/* <LinkButton route={'/studio'} state={this.state} btnText={'Schedule Studio Time'} /> */}
                            <span className="special-text">Call today to schedule studio time for your next recording session! Online scheduling portal coming soon! </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>{/* 
                <Container className="history-section">
                    <Row>
                        <Col lg={12}>
                            <h2>The History of 3rd Street Music </h2>
                        </Col>
                        <Col  xs={{order: 3, span: 12}} sm={{order: 3, span: 12}}  md={{span: 6, order: 1}} lg={{span: 7, order: 1}}>
                            <p>
                              History description
                            </p>
                        </Col>
                        <Col xs={{order: 1, span: 12}} sm={{ span: 12, order: 1}} md={{span: 6, order: 2}} lg={{span: 5, order: 2}}>
                            <img className="home-img" src={require('../../../img/history.jpg')} alt="" />
                        </Col>
                    </Row>
                </Container> */}
            </div>
        )
    }
}

export default Home;