import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import moment from 'moment';
import './Scheduling.css';
import PersonalCalendar from '../../items/Schedule/personalCalendar';
import { connect } from 'react-redux';
import NewEventModal from '../../items/Modals/NewEventModal';
import ReactGA from 'react-ga';
import axios from 'axios';
import NewUserModal from '../../items/Modals/NewUserModal';

class Scheduling extends React.Component {
  constructor(props) {
    super(props);
    if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    var user = this.props.user;

    let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Week, false, false, {
      views: [
        {viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
        {viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
        {viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false}
      ]
    });

    moment.locale('zh-cn');
    schedulerData.setLocaleMoment(moment);

    this.state = {
      events: [],
      schedulerData: schedulerData,
      schedulerIsReady: false,
      event: {}
     };
  }

  componentWillReceiveProps(newProps) {
    if(newProps.user) {
      this.loadScheduler(newProps.user);
    }
  }

  async loadScheduler(user) {
    var body;
    if(user){
      body = JSON.stringify({user})
    }
  
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    var events = (await axios.get(`${ process.env.REACT_APP_API_URL }/events/user/${ user.id }`, body, config)).data.events;

    var finalEvents = events.map(event =>
      (event.studio) 
      ? {
       start: moment(event.event.start).format('YYYY-MM-DD HH:mm:ss'),
       end: moment(event.event.end).format('YYYY-MM-DD HH:mm:ss'),
       title: event.user.first_name + ' ' + event.user.last_name,
       resourceId: 's' + event.studio.id,
       id: event.event.id,
       studio: event.studio,
       client: event.user
      } : {
      start: moment(event.event.start).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(event.event.end).format('YYYY-MM-DD HH:mm:ss'),
      title: event.user.first_name + ' ' + event.user.last_name,
      resourceId: 't' + event.teacher.id,
      id: event.event.id,
      teacher: event.teacher,
      client: event.user
    });
    var resources = events.map(event => event = {
      name: (event.studio) ? event.studio.name : event.teacher.first_name + ' ' + event.teacher.last_name,
      id: (event.studio) ? 's' + event.studio.id : 't' + event.teacher.id
    });

    let uniqueResources = [];
    const map = new Map();
    for (const resource of resources) {
        if(!map.has(resource.id)){
            map.set(resource.id, true);    // set any value to Map
            uniqueResources.push(resource);
        }
    }

    //var uniqueResources = Array.from(new Set(resources.map()))
    this.state.schedulerData.setResources(uniqueResources);

    this.setState({events: finalEvents});
    this.state.schedulerData.setEvents(finalEvents);
    this.setState({schedulerIsReady: true});
  }

  async componentDidMount() {
    await window.scrollTo(0, 0);
    var user = this.props.user;
    if(user && user.role) {
      this.loadScheduler(user);
    }
  }

  render() {
    if(!this.props.isAuthenticated){
      this.props.history.push('/');
      return null;
    }
    const role = (this.props.user && this.props.user.role) ? this.props.user.role.name : 'Student';
    return (
      <div className="SchedulingPage">
      <div className="schedulingBg"></div>
      <Container>
        <Row>
          <Col style={{paddingTop: '30px'}}>
            <h5>Welcome to the scheduling page!</h5>
            <p>Here you can see all of your scheduled music lessons or studio sessions.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              (role == 'Organizer')
              ? <NewEventModal schedulerData={this.state.schedulerData} newEvent={this.newEvent} events={this.state.events} /> 
              : null
            }
            &nbsp;&nbsp;
            {
              (role == 'Organizer')
              ? <NewUserModal />
              : null
            }
          </Col>
        </Row>
      </Container>
        <Container fluid={true}>
          <Row>{ this.state.schedulerIsReady
          ? <PersonalCalendar schedulerData={this.state.schedulerData} 
                 prevClick={this.prevClick}
                 nextClick={this.nextClick}
                 onSelectDate={this.onSelectDate}
                 onViewChange={this.onViewChange}
                 eventItemClick={this.eventClicked}
                 eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
              />
              : null }
          </Row>
        </Container>
      </div>
    )
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({
        viewModel: schedulerData
    });
  }

  nextClick = (schedulerData)=> {
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  }

  deleteEvent = async(e, event) => {
      var body = {
        event_id: event.id
      };
      await axios.post(`${ process.env.REACT_APP_API_URL }/events/session/delete`, body)
        .then(result => {
          if(result.status == 200) {
            alert('Event deleted!');
          } else {
            alert('Failed to delete event');
          }
        }).catch(err => {
          console.log(err);
        })
  }

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    });
  }

  // This is available to everyone
  eventItemClick = (schedulerData, event) => {
    return false;
    // Check if user is authenticated, if authenticated, present user with schedule info modal.
    // if user is not authenticated, present them with the login/register modal

    // If user schedules, alert them with a thank you, and fire an email to them and miles and maybe the teacher?
  };

  // This one should be locked down to Miles only.
  newEvent = (schedulerData, newEvent) => {
    schedulerData.addEvent(newEvent);
    this.setState({viewModel: schedulerData});
  }

  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end) => {
    const client = eventItem.client

    return (
      <div style={{width: '300px'}}>
        <Row>
          <Col span={22} className="overflow-text">
            <span className="header2-text" title={eventItem.title}>{client.first_name + ' ' + client.last_name}</span> <br/>
          </Col>
          <Col span={2} className="overflow-text">
            <span className="header2-text" title="time">Time</span>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
          {client.email} <br/>
            {(client.phone) ? client.phone : null}
          </Col>
          <Col span={16}>
            {start.format("MM/DD/YYYY")} <br/>
            <span>{start.format("hh:mm A")} - {end.format("hh:mm A")}</span>
          </Col>
        </Row>
        {
          (this.props.user.role.name == 'Organizer')
          ? <Row>
              <Col>
                <Button onClick={e => this.deleteEvent(e, eventItem)} className="call-to-action-btn">Delete Event</Button>
              </Col>
            </Row>
          : null
        }
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(Scheduling);