import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import '../../main/Lessons/Lessons.css';

class TeacherCard extends React.Component {
    // When component gets loaded
    componentDidMount() {
        // Jump to top of window
        window.scrollTo(0, 0);
    }

    render(){
        const {name, desc, fileName} = this.props.teacher;
        return (
            <div className="TeacherCard">
                <Card>
                    <Card.Img variant="top" src={require('../../../img/' + fileName)} />
                    <Card.Body>
                        <Card.Title>{name}</Card.Title>
                        <Card.Text>{desc}</Card.Text>
                        <Link to={{
                            pathname: '/lessons/' + name,
                            state: {teacher: this.props.teacher}
                        }}>
                            <Button className="call-to-action-btn card-btn">Schedule Lessons</Button>
                        </Link>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default TeacherCard;