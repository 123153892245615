import React from 'react';
import Scheduler from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import DnD from '../DnDContext';

class personalCalendar extends React.Component {
  render(){
    const {schedulerData, prevClick, nextClick, onSelectDate, onViewChange, eventItemClick, newEvent, eventItemPopoverTemplateResolver} = this.props;
    return (
      <Scheduler schedulerData={schedulerData}
        prevClick={prevClick}
        nextClick={nextClick}
        onSelectDate={onSelectDate}
        onViewChange={onViewChange}
        eventItemClick={eventItemClick}
        newEvent={newEvent}
        eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
      />
    )
  }
}

export default DnD(personalCalendar);