import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './Buttons.css';

class LinkButton extends React.Component {
    render(){
        const {route, state, btnText} = this.props;
        return (
              <Link to={{
                  pathname: route,
                  state: state
              }}>
                  <Button className="call-to-action-btn card-btn">{btnText}</Button>
              </Link>
        )
    }
}

export default LinkButton;