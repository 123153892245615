import axios from 'axios';
import { returnErrors } from './errorActions';

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from '../actions/types';


// Check token and load user
export const loadUser = () => async(dispatch, getState) => {
  // User loading 
  dispatch({type: USER_LOADING});
  await axios.get(`${ process.env.REACT_APP_API_URL }/auth/user`, tokenConfig(getState))
    .then(res => dispatch({
      type: USER_LOADED,
      payload: res.data.user
    })) 
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR
      });
    });
}

// Register user
export const register = (user) => dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  axios.post(`${ process.env.REACT_APP_API_URL }/user`, user, config)
  .then(res => dispatch => ({
      type: REGISTER_SUCCESS,
      payload: res.data
    })).catch(err => {
      alert('A user with that email already exists. Please try again!')
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: REGISTER_FAIL
      });
    });
}

// Login User
export const login = ({ email, password }) => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Request body
  const body = JSON.stringify({ email, password });
  console.log('logging in')
  axios
    .post(`${ process.env.REACT_APP_API_URL }/auth/`, body, config)
    .then(async(res) => {
      console.log(res)
      await dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
      loadUser();
    })
    .catch(err => {
      console.log(err)
      dispatch(
        returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL')
      );
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

// Logout User
export const logout = () => {
  window.location.href = '/';
  return {
    type: LOGOUT_SUCCESS
  };
};

// Setup config/headers and token
export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  // If token add to headers
  if(token) {
    config.headers['x-auth-token'] = token;
  }

  return config;
}