import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import '../../main/Studio/Studio.css';

class StudioCard extends React.Component {
    // When component gets loaded
    componentDidMount() {
        // Jump to top of window
        window.scrollTo(0, 0);
    }

    render(){
        const {name, desc, fileName} = this.props.studio;
        return (
            <div className="StudioCard">
                <Card>
                    <Card.Img variant="top" src={require('../../../img/' + fileName)} />
                    <Card.Body>
                        <Card.Title>{name}</Card.Title>
                        <Card.Text>{desc}</Card.Text>
                        <Link to={{
                            pathname: '/studios/' + name,
                            state: {studio: this.props.studio}
                        }}>
                            <Button className="call-to-action-btn card-btn">Schedule Studio Time</Button>
                        </Link>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default StudioCard;