import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Recaptcha from 'react-google-recaptcha';
import axios from 'axios';

class ContactForm extends React.Component {
  constructor(){
    super();

    this.state = {
      validated: false,
      phone: '',
      recaptcha: '',
      formControls: {
        phone: '',
        email: '',
        name: '',
        message: ''
      }
    }
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  async changeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: value
      }
    });
  }

  // String insert function
  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // Prevent the default submit action
      event.preventDefault();
      event.stopPropagation();
    }
    await this.setState({ validated: true });
    axios.post(`${ process.env.REACT_APP_API_URL }/email/`+ 4 + '/send', this.state.formControls)
      .then(response => {
        var success = response.data.success;
        if(success === true){
          alert('Email sent successfully. We\'ll be in touch with you soon.');
        } else if(success === false){
          alert('Failed to send email. Please try again.');
        }
        
      }).catch(err => {
        alert('Failed to send email. Please try again.');
      })
  }

  validatePhone(input) {
      // Check if input length is less than or greater than state number
      var number = input.target.value;

      // If number.length == 10 (coming from autofill)
      if(number.length === 10 && !number.includes('-')){
          number = this.insert(number, 3, '-');
          number = this.insert(number, 7, '-');
      }

      // If number.length is less than the state, user is deleting and state should be updated
      if (number.length < this.state.phone.length){
        this.setState({phone: number});
        this.changeHandler(input);
      }

      // If greater, add to state as long as number.length < 13
      else if(number.length < 13 && number.length > this.state.phone.length){
        if(number.length === 3 || number.length === 7) {
            number = number + '-';
        }
        this.setState({phone: number});
        this.changeHandler(input)
      }
  }

  validateEmail(input) {
      var email = input.target.value;
      var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if(re.test(email) === true){
          document.getElementById('formEmail').style.border = '';
          this.changeHandler(input);
      } else {
          document.getElementById('formEmail').style.border = '2px solid red';
      }
      return re.test(email);
  }

  verifyCallback(response) {
    this.setState({recaptcha: response});
  };

  
  render(){
    return (
      <div className="ContactForm">
        <Form onSubmit={e => this.handleSubmit(e)}>
            <Form.Group controlId="formName">
                <Form.Label>Name*</Form.Label>
                <Form.Control type="text" name="name" placeholder="Enter your name" onChange={e => this.changeHandler(e)} required />
            </Form.Group>
            <Form.Group controlId="formEmail">
                <Form.Label>Email*</Form.Label>
                <Form.Control type="email" name="email" placeholder="Enter your email" onChange={e => this.validateEmail(e)} required />
            </Form.Group>
            <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="tel" name="phone" placeholder="(xxx)-xxx-xxxx" value={this.state.phone} onChange={e => this.validatePhone(e)}/>
            </Form.Group>
            <Form.Group controlId="formMessage">
                <Form.Label>Message*</Form.Label>
                <Form.Control as="textarea" name="message" onChange={e => this.changeHandler(e)} rows="3" placeholder="Drop a message!" required />
            </Form.Group>
            <Recaptcha sitekey="6LdOjKgUAAAAAACa6FSS5ZOfungh3VMY1Eh7TlkV" onChange={this.verifyCallback}/>
            <Button type="submit" disabled={!(this.state.recaptcha)} className="call-to-action-btn">Send Message</Button>
        </Form>

      </div>
    )
  }
}

export default ContactForm;