import { GET_EVENTS, DELETE_EVENT, UPDATE_EVENT, ADD_EVENT, EVENTS_LOADING } from './types';
import axios from 'axios';
import { returnErrors } from './errorActions';
import { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import moment from 'moment';
import { async } from 'q';
/* 
export default getEvents = (studio = null, teacher = null) => {
  var body;

  if(studio){
    body = JSON.stringify({studio})
  }
  if(teacher){
    body = JSON.stringify({teacher})
  }

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  const request = axios.get(`${ process.env.REACT_APP_API_URL }/events/studio/${ studio.id }`, body, config)

  return (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: GET_EVENTS, payload: success });
      return success;
    }
    function onError(error) {
      dispatch(returnErrors(error.response.data, error.response.status))
      return error;
    }
    request.then(success => onSuccess, error => onError);
  };
} */
/* 

export const getEvents = (studio = null, teacher = null) => (getState) => {
  var body;

  if(studio){
    body = JSON.stringify({studio})
  }
  if(teacher){
    body = JSON.stringify({teacher})
  }

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  return async(dispatch) => {
  dispatch(setEventsLoading)
    try{
      const events = await axios.get(`${ process.env.REACT_APP_API_URL }/events/studio/${ studio.id }`, body, config)
      console.log(events)
      dispatch({
        type: GET_EVENTS,
        payload: events
      })
    } catch(err) {
        dispatch(returnErrors(err.response.data, err.response.status))
}
  }
}
 */
export const updateEvent = (eventItem) => {

}

export const deleteEvent = (eventItem) => {

}

export const addEvent = (event, user) => (dispatch, getState) => {
  const token = getState().auth.token;
  const { start, end, host, student } = event;
  const body = JSON.stringify({ host, student, user, start, end });

  const config = {
    headers: {
      "Content-Type": 'application/json',
      "x-auth-token": token
    }
  }
  
  axios.post(`${ process.env.REACT_APP_API_URL }/events/new`, body, config)
    .then(res => dispatch({
      type: ADD_EVENT,
      payload: event
    }))
  return {
    type: ADD_EVENT
  }
}

export const setEventsLoading = () => {
  return {
    type: EVENTS_LOADING
  };
};