import React, { Fragment } from 'react';
import { Navbar, Nav }from 'react-bootstrap';
import { Route, BrowserRouter as Router, Switch, NavLink } from 'react-router-dom';
import Home from '../../main/Home/Home';
import Scheduling from '../../main/Scheduling/Scheduling';
import Lessons from '../../main/Lessons/Lessons';
import Showroom from '../../main/Showroom/Showroom';
import Studio from '../../main/Studio/Studio';
import Contact from '../../main/Contact/Contact';
import TeacherCalendar from '../../items/Teacher/TeacherCalendar';
import StudioCalendar from '../../items/Studio/StudioCalendar';
import RegisterModal from '../../auth/RegisterModal';
import LoginModal from '../../auth/LoginModal';
import Logout from '../../auth/Logout';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Header.css';
import authReducer from '../../../reducers/authReducer';

class Header extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  }
    render(){
      const { isAuthenticated, user } = this.props.auth;
      const authLinks = (
        <Fragment>
            <Nav.Item>
              <span style={{color: 'white'}}>
                <strong>{ user ? `Welcome ${user.first_name}!` :  '' }</strong>
              </span>
            </Nav.Item>
            <Nav.Link as={NavLink} to='/scheduling'>My Schedule</Nav.Link>
            <Logout />
        </Fragment>
      )
      const guestLinks = (
        <Fragment>
            {/* <RegisterModal /> */}
            <LoginModal />
        </Fragment>
      )
        return (
          <Router>
              <div className="Header">
                <Navbar collapseOnSelect expand="lg" fixed="top">
                  <Navbar.Brand href="/"><img className="d-inline-block align-top" src={require('../../../img/3rdstreet.png')} alt="" width="100px" height="100px"/></Navbar.Brand>
                  <Navbar.Toggle className="custom-toggler" aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link as={NavLink} to="/" exact>About Us</Nav.Link>
                      <Nav.Link as={NavLink} to="/showroom">Showroom</Nav.Link>
                      <Nav.Link as={NavLink} to="/lessons">Lessons</Nav.Link>
                      <Nav.Link as={NavLink} to="/studio">Studio Sessions</Nav.Link>
                      <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
                    </Nav>
                    <Nav>
                      { isAuthenticated ? authLinks : guestLinks }
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                {/* This 'Switch' will load our components based on navigation. */}
                <Switch>
                      <Route exact path="/" component={ Home }/>
                      <Route exact path="/lessons" component={ Lessons } />
                      <Route exact path="/showroom" component={ Showroom } />
                      <Route exact path="/studio" component={ Studio } />
                      <Route exact path="/contact" component={ Contact } />
                      <Route exact path="/scheduling" component={ Scheduling } />

                      {/* These routes are specific to teachers and studios, passes down some props */}
                      {/* <Route path="/lessons/:teacher" component={ TeacherCalendar } /> */}
                      <Route path="/studios/:studio" component={ StudioCalendar } />

                      {/* If a user tries to visit an undefined route, redirect to home component */}
                      <Route component={ Home } />
                </Switch>
              </div>
          </Router>
        )
    }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, null)(Header);