import React from 'react';
import './Studio.css';
import '../../main/Lessons/Lessons.css';
import { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import moment from 'moment';
import Calendar from '../Schedule/Calendar';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addEvent } from '../../../actions/scheduleActions';
import PropTypes from 'prop-types';
import NewEventModal from '../Modals/NewEventModal';
import SweetAlert from 'sweetalert-react';
import axios from 'axios';

class StudioCalendar extends React.Component {
  constructor(props) {
    super(props);
    var studio = this.props.location.state.studio;

    let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Week, false, false, {
      views: [
        {viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
        {viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
        {viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false}
      ]
    });


    moment.locale('zh-cn');
    schedulerData.setLocaleMoment(moment);

    
    var resources = [{
      name: studio.name,
      id: studio.id
    }]
    schedulerData.setResources(resources);

    let satHours = [
      {hour: 10, min: 0},
      {hour: 10, min: 30},
      {hour: 11, min: 0},
      {hour: 11, min: 30},
      {hour: 12, min: 0},
      {hour: 12, min: 30},
      {hour: 13, min: 0},
      {hour: 13, min: 30},
      {hour: 14, min: 0}
    ];

    let MthruRHours = [
      {hour: 14, min: 0},
      {hour: 14, min: 30},
      {hour: 15, min: 0},
      {hour: 15, min: 30},
      {hour: 16, min: 0},
      {hour: 16, min: 30},
      {hour: 17, min: 0},
      {hour: 17, min: 30},
      {hour: 18, min: 0},
      {hour: 18, min: 30},
      {hour: 19, min: 0},
      {hour: 19, min: 30},
      {hour: 20, min: 0}
     ];

    this.state = {
      studio: studio,
      events: [],
      schedulerData: schedulerData,
      schedulerIsReady: false,
      MthruRHours,
      event: {},
      satHours
     };
  }

  static propTypes = {
    events: PropTypes.array.isRequired,
    event: PropTypes.object,
    eventItemPopoverTemplateResolver: PropTypes.func
  }

  // When component gets loaded
  async componentDidMount() {
    var MthruRHours = this.state.MthruRHours;
    var satHours = this.state.satHours;
    // Jump to top of window
    await window.scrollTo(0, 0);
    var body;
    var studio = this.state.studio
    if(studio){
      body = JSON.stringify({studio})
    }
  
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    var events = (await axios.get(`${ process.env.REACT_APP_API_URL }/events/studio/${ studio.id }`, body, config)).data.events;

    var reservedSpots = events.map(event => event.event)

    
    
    // Get all days in month that third street is open, set the hours for those days available lessons
    // This is only needed for studio sessions and music lessons, not users personal schedule
    let currentDate = new Date();
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var date = new Date(year, month, 1);
    var days = [];
    let MthruR = [1, 2, 3, 4];
    var idIncrement = 0;
    while(date.getMonth() === month) {
      if(MthruR.includes(date.getDay())){
        // Create events for each date
        for(var i = 0; i < MthruRHours.length - 1; i++){
          days.push({
            start: date.setHours(MthruRHours[i].hour, MthruRHours[i].min), 
            end: date.setHours(MthruRHours[i + 1].hour, MthruRHours[i + 1].min), 
            title: 'Open',
            resourceId: studio.id,
            id: idIncrement,
            studio: this.props.location.state.studio
          })
          idIncrement++
        }
      } else if(date.getDay() === 6) {
        for(var i = 0; i < satHours.length - 1; i++){
          days.push({
            start: date.setHours(satHours[i].hour, satHours[i].min), 
            end: date.setHours(satHours[i + 1].hour, satHours[i + 1].min),
            title: 'Open',
            resourceId: studio.id,
            id: idIncrement,
            studio: this.props.location.state.studio
          })
          idIncrement++
        }
      }
      date.setDate(date.getDate() + 1);
    }

    var startEvents = reservedSpots.map(event => {
      return (new Date(event.start)).getTime();
    });
    
    var availableEvents = days.filter(event => !startEvents.includes(event.start))
    this.setState({events: availableEvents});
    this.state.schedulerData.setEvents(availableEvents);
    this.setState({schedulerIsReady: true});
  }

  render(){
    return (
      <div className="StudioCalendar">
          <div className="picBg">
          </div>
          <Container>
            <Row>
              <Col>
                <h2>{this.state.studio.name}</h2>
                <h4 className="offset-1">Schedule Studio Time</h4>
              </Col>
            </Row>
          </Container>
          <Container fluid={true}>
            <Row>
              { this.state.schedulerIsReady 
                ?  <Calendar schedulerData={this.state.schedulerData} 
                 prevClick={this.prevClick}
                 nextClick={this.nextClick}
                 onSelectDate={this.onSelectDate}
                 onViewChange={this.onViewChange}
                 eventItemClick={this.eventItemClick}
                 newEvent={this.newEvent}
                 eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}/>
                 : null }
                 
            </Row>
          </Container>
      </div>
    )
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({
        viewModel: schedulerData
    })
  }

  nextClick = (schedulerData)=> {
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  // This is available to everyone
  eventItemClick = (schedulerData, event) => {
    return false;
    // Check if user is authenticated, if authenticated, present user with schedule info modal.
    // if user is not authenticated, present them with the login/register modal

    // If user schedules, alert them with a thank you, and fire an email to them and miles and maybe the teacher?
  };

  // This one should be locked down to Miles only.
  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    return false;
  }


  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end) => {
    return (
      <div style={{width: '300px'}}>
        <Row>
          <Col span={22} className="overflow-text">
            <span className="header2-text" title={title}>{title}</span>
          </Col>
          <Col span={2} className="overflow-text">
            <span className="header2-text" title="time">Time</span>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            {start.format("MM/DD/YYYY A")}
          </Col>
          <Col span={16}>
            <span>{start.format("hh:mm A")} - {end.format("hh:mm A")}</span>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <NewEventModal eventItem={eventItem}/>
          </Col>
        </Row>
      </div>
    );
  }
}





const mapStateToProps = (state) => ({
  events: state.event.events,
  event: state.event
})

export default connect(mapStateToProps, { addEvent })(StudioCalendar);