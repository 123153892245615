import React from 'react';
import { Carousel, Card, CardDeck } from 'react-bootstrap';
import './ControlledCarousel.css';


class ControlledCarousel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
      brands: props.brands
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  render() {
    const { index, direction } = this.state;

    return (
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
        fade={true}
      >
        <Carousel.Item>
            <CardDeck>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/danelectro.jpeg')} />
                <Card.Body>
                  <Card.Title>Dan Electro</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/dangelico.jpg')} />
                <Card.Body>
                  <Card.Title>D'Angelico Guitars</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/lespaul.jpg')} />
                <Card.Body>
                  <Card.Title>Gibson</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/hamer.jpeg')} />
                <Card.Body>
                  <Card.Title>Hamer</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
        </Carousel.Item>
        <Carousel.Item>
            <CardDeck>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/gretsch.jpg')} />
                <Card.Body>
                  <Card.Title>Gretsch</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/fender.jpg')} />
                <Card.Body>
                  <Card.Title>Fender</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/yamaha.jpg')} />
                <Card.Body>
                  <Card.Title>Yamaha</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="guitarCard">
                <Card.Img variant="top" className="guitarBrand" src={require('../../../img/GuitarBrands/ibanez.jpg')} />
                <Card.Body>
                  <Card.Title>Ibanez</Card.Title>
                  <Card.Text>
                    
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default ControlledCarousel;