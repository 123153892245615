import React from 'react';
import ContactForm from '../../items/Forms/ContactForm';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import './Contact.css';
import ReactGA from 'react-ga';

class Contact extends React.Component {
    constructor(){
      super();
      this.state = {
        
      }
      if(process.env.REACT_APP_ENVIRONMENT_MODE === 'production'){
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }

    // Jump to top of window
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <div className="Contact">
                <div className="picBg">
                </div>
                <Container className="about-section">
                    <Row>
                        <Col>
                            <h2>Contact Us</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                          <ContactForm />
                        </Col>
                    </Row>
                </Container>
                <div className="map-box">
                    <iframe title="gMapLocation" className="g-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3082.978592137163!2d-84.56091518477487!3d39.401990379495935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884046f8b455ce07%3A0x4db73902b6456a50!2s3rd+Street+Music!5e0!3m2!1sen!2sus!4v1559381497674!5m2!1sen!2sus" frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
        )
    }
  }

export default Contact;